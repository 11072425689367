<template>
  <!-- medical docs section -->
  <div class="my-5 overflow-hidden">
    <slot></slot>
    <div class="card">
      <div class="card-header px-4 py-3">{{ cardHeader }}</div>

      <form
        v-if="medicalDocs.length > 0"
        id="PrimaryMedicalConditionSection"
        ref="MedicalDocUploader"
        class="d-flex flex-wrap p-4 card-body"
        enctype="multipart/form-data"
      >
        <div
          v-for="(doc, idx) in medicalDocs"
          :key="doc.api_title"
          class="w-50 mb-5"
        >
          <p class="d-inline">{{ doc.title }}</p>
          <img
            :src="require(`@/assets/svg/${doc.status}.svg`)"
            class="ms-2"
            :class="{ 'icon-success': isDownloaded(doc.value) }"
          />
          <div class="d-flex justify-content-start align-items-center my-3">
            <div class="me-3">
              <!-- upload button -->
              <div
                v-if="!readOnly"
                class="d-flex justify-content-center align-items-center file-upload-btn"
              >
                <!-- <label
                  :for="`${doc.api_title}`"
                  class="px-5 py-2 upload-btn border rounded-2"
                  :class="
                    doc.status === 'unavailable'
                      ? 'border-gray-400 text-gray-400'
                      : doc.status === 'waiting'
                      ? 'border-warning text-waiting enable-pointer'
                      : 'border-success text-success enable-pointer'
                  "
                >
                  <img
                    src="@/assets/svg/folder.svg"
                    class="me-2"
                    :class="
                      doc.status === 'unavailable'
                        ? 'd-none'
                        : doc.status === 'waiting'
                        ? 'icon-waiting'
                        : 'icon-success'
                    "
                  />
                  {{ doc.status === 'unavailable' ? '-' : 'Upload' }}
                </label> -->
                <!-- <input
                  :id="`${doc.api_title}`"
                  type="file"
                  :disabled="doc.status === 'unavailable' ? true : false"
                  multiple
                  class="d-none"
                  :name="`${doc.api_title}`"
                  accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  @change="emitFileUpload($event, idx)"
                /> -->

                <VueUploadComponent
                  :ref="doc.api_title"
                  v-model="doc.value"
                  class="file-upload-btn"
                  post-action="/upload/post"
                  :multiple="true"
                  :size="1024 * 1024 * 10"
                  :name="doc.api_title"
                  :disabled="doc.status === 'unavailable' ? true : false"
                  @input-file="doc.status = 'included'"
                >
                  <div
                    class="px-5 py-2 upload-btn border rounded-2 d-flex justify-content-center align-items-center"
                    :class="
                      doc.status === 'unavailable'
                        ? 'border-gray-400 text-gray-400'
                        : doc.status === 'waiting'
                        ? 'border-warning text-waiting enable-pointer'
                        : 'border-success text-success enable-pointer'
                    "
                  >
                    <img
                      src="@/assets/svg/folder.svg"
                      class="me-2"
                      :class="
                        doc.status === 'unavailable'
                          ? 'd-none'
                          : doc.status === 'waiting'
                          ? 'icon-waiting'
                          : 'icon-success'
                      "
                    />
                    {{ doc.status === 'unavailable' ? '-' : 'Upload' }}
                  </div>
                </VueUploadComponent>
              </div>

              <div v-else>
                <button
                  class="btn rounded-3 bg-transparent border px-4 download-btn"
                  :class="
                    doc.status === 'included'
                      ? 'border-success text-success'
                      : 'text-gray-500 w-1/2'
                  "
                  :disabled="doc.status === 'unavailable' ? true : false"
                  type="button"
                  @click="emitDownloadFile({ index: idx, ...doc })"
                >
                  <img
                    src="@/assets/svg/folder.svg"
                    class="me-2"
                    :class="
                      doc.status === 'included' ? 'icon-success' : 'd-none'
                    "
                  />
                  <span class="mt-1">
                    {{ doc.status === 'included' ? 'Download' : '-' }}
                  </span>
                </button>
              </div>
            </div>
            <!-- not available button -->
            <div>
              <div v-if="!readOnly">
                <input
                  :id="`${doc.api_title + '-check-btn'}`"
                  type="checkbox"
                  class="btn-check border-1 px-3 py-2 bg-transparent"
                  autocomplete="off"
                  @change="emitSetUnavailable(idx)"
                />
                <label
                  class="btn border-1 border d-flex justify-content-center align-items-center px-3 py-2"
                  :for="`${doc.api_title + '-check-btn'}`"
                  :class="
                    doc.status === 'unavailable'
                      ? 'border-gray-600 text-gray-600'
                      : 'border-gray-400 text-gray-400'
                  "
                >
                  <div
                    :class="
                      doc.status === 'unavailable' ? 'square' : 'empty-square'
                    "
                  ></div>
                  <span class="ms-2">Not Available</span>
                </label>
              </div>

              <div
                v-else
                class="rounded-2 border-1 border d-flex justify-content-center align-items-center px-3 py-2"
                :class="
                  doc.status === 'unavailable'
                    ? 'border-gray-600 text-gray-600'
                    : 'border-gray-400 text-gray-400'
                "
              >
                <div
                  :class="
                    doc.status === 'unavailable' ? 'square' : 'empty-square'
                  "
                ></div>
                <span class="ms-2">Not Available</span>
              </div>
            </div>
          </div>
          <div v-if="doc.value.length > 0" class="mt-2">
            <div
              v-for="(files, fileIdx) in doc.value"
              :key="files.name ? files.name : files.file_name"
              style="font-size: 13px"
              class="d-flex align-items-center"
            >
              <button
                v-if="!readOnly"
                type="button"
                class="btn border-0 bg-transparent text-danger btn-sm"
                @click="
                  emitDelFile({
                    category: doc.title,
                    file_index: fileIdx,
                    ...files,
                  })
                "
              >
                x
              </button>
              <div v-else><span class="circle-success me-1"></span></div>
              <div>
                <span v-if="files.file_name">
                  {{ files.file_name }}
                </span>
                <span v-else>
                  {{ files.name ?? files.display_name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div v-else class="p-4 my-5 card-body text-gray-400 fw-light text-center">
        No patient documents or records uploaded yet.
      </div>
      <slot name="legends"></slot>
    </div>
  </div>
</template>

<script>
import VueUploadComponent from 'vue-upload-component'
export default {
  components: { VueUploadComponent },
  props: {
    medicalDocs: {
      type: Array,
      default: () => [],
    },

    cardHeader: {
      type: String,
      default: 'Primary Medical Condition',
    },

    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'setUnavailable',
    'onFileUpload',
    'downloadFile',
    'delFile',
    'submit',
    'updateFormData',
  ],

  data() {
    return {}
  },
  methods: {
    emitDownloadFile(value) {
      return this.$emit('downloadFile', value)
    },

    emitSubmit(value) {
      return this.$emit('downloadFile', value)
    },

    emitFileUpload(e, idx) {
      let obj = { value: e.target.files, index: idx }

      let form_data = new FormData(this.$refs.MedicalDocUploader)
      this.$emit('updateFormData', form_data)

      return this.$emit('onFileUpload', obj)
    },
    emitSetUnavailable(idx) {
      this.$emit('setUnavailable', idx)
    },

    emitDelFile(file) {
      return this.$emit('delFile', file)
    },
    isDownloaded(docValues) {
      if (docValues.length) {
        return docValues.every((element) => element['last_download_at'])
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-gray {
  filter: brightness(0) saturate(100%) invert(99%) sepia(13%) saturate(0%)
    hue-rotate(142deg) brightness(82%) contrast(86%);
}

#PrimaryMedicalConditionSection {
  flex: 0 0 50%;

  & label,
  .download-btn {
    font-size: 1rem;
    font-weight: 400;

    outline: 0;
    user-select: none;
    min-width: 165.7px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .icon-success {
    filter: brightness(0) saturate(100%) invert(62%) sepia(92%) saturate(445%)
      hue-rotate(72deg) brightness(83%) contrast(81%);
  }

  & .icon-waiting {
    filter: brightness(0) saturate(100%) invert(79%) sepia(30%) saturate(3041%)
      hue-rotate(354deg) brightness(104%) contrast(101%);
  }

  & img {
    filter: brightness(0) saturate(100%) invert(99%) sepia(13%) saturate(0%)
      hue-rotate(142deg) brightness(82%) contrast(86%);
  }

  & .upload-btn {
    min-width: 165.7px;

    & input:disabled {
      cursor: none;
    }
  }
}

.square {
  height: 16px;
  width: 16px;
  border-radius: 3px;
  background-color: #6c757d;
}

.empty-square {
  height: 16px;
  width: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
}

.circle {
  &-success {
    border-radius: 50%;
    background-color: #27c92f;
    min-height: 8px;
    min-width: 8px;

    display: inline-block;
  }
}

.enable-pointer {
  cursor: pointer;
}
</style>
