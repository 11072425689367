<template>
  <div>
    <div v-if="pageReady" class="">
      <h2 class="fs-2 my-4 text-primary text-center">
        Referral Information Upload
      </h2>
      <div class="py-4 d-flex flex-column">
        <!-- legal docs section -->
        <div class="my-5">
          <div class="d-flex justify-content-between align-items-center">
            <div class="w-50">
              <div class="d-flex justify-content-between mb-2">
                <h2 class="fs-3 text-dark d-inline">Legal Documents</h2>
                <div class="d-flex align-items-center">
                  <img
                    :src="require(`@/assets/svg/${briefingLetterStatus}.svg`)"
                  />
                  <span class="ms-2 fw-light">Briefing Letter</span>
                </div>
              </div>
              <span class="fw-light text-gray-600">
                Please upload the letter along with the documents related to it
              </span>
            </div>

            <div
              class="d-flex justify-content-center align-items-center file-upload-btn flex-1"
            >
              <VueUploadComponent
                ref="uploadLegalDocs"
                v-model="legalDocs.value"
                class="file-upload-btn border rounded-2 p-2 px-3"
                post-action="/upload/post"
                :multiple="true"
                :size="1024 * 1024 * 10"
                name="legalDocs"
              >
                <div class="d-flex justify-content-center align-item-center">
                  <img src="@/assets/svg/folder.svg" class="me-2 icon-gray" />
                  Upload of referral legal letter
                </div>
              </VueUploadComponent>
            </div>
          </div>

          <div
            v-if="legalDocs.value && legalDocs.value.length > 0"
            class="mt-2"
          >
            <div
              v-for="files in legalDocs.value"
              :key="files.name ? files.name : files.file_name"
              style="font-size: 13px"
              class="d-flex align-items-center"
            >
              <button
                class="btn border-0 bg-transparent text-danger btn-sm"
                @click="
                  storeDocToRemove({ category: 'legal documents', ...files })
                "
              >
                x
              </button>

              <div>
                <a
                  v-if="files.file_name"
                  :href="files.file"
                  target="_blank"
                  :download="files.file_name"
                >
                  {{ files.file_name }}
                </a>
                <span v-else class="fw-light">{{ files.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MedicalDocUploader
        :medical-docs="medicalDocs"
        :card-header="``"
        @on-file-upload="onFileUpload"
        @set-unavailable="setUnavailable"
        @update-form-data="storeFormData"
        @del-file="storeDocToRemove"
      >
        <div class="my-4">
          <h2 class="py-1 fs-3 text-dark">Medical Documents</h2>
          <span class="fw-light text-gray-600">
            Please upload related patient medical documents according to its
            category.
            <br />
            Please select the "Not available" if the file is not available.
          </span>
        </div>

        <template #legends>
          <div class="my-4 mx-auto w-50 d-flex justify-content-center">
            <div
              v-for="legend in medicalDocLegends"
              :key="legend.title"
              class="mx-2"
            >
              <img
                :src="require(`@/assets/svg/${legend.status}.svg`)"
                class="me-2"
              />
              <span>{{ legend.title }}</span>
            </div>
          </div>
        </template>
      </MedicalDocUploader>

      <!-- referral history section -->
      <div class="my-5">
        <h2 class="fs-3 text-dark d-inline">Referral History (optional)</h2>
        <div class="mt-4">
          <editor
            id="referral_history_tpd"
            v-model="referralHistory"
            api-key="rqlshe39tnmswzraq3qm2yq3hs8z1dkr03i92xlachrasuh9"
            :init="{
              plugins: 'lists link image table code help wordcount',
            }"
          />
        </div>
      </div>

      <!-- medical diagnosis section -->
      <div class="my-5">
        <h2 class="fs-3 text-dark d-inline mb-4">
          Medical Diagnosis/Condition (optional)
        </h2>
        <div>
          <div
            v-for="(input, key) in medicalDiagnosis"
            :key="key"
            class="d-flex align-items-center my-4"
          >
            <div class="fw-bold me-2">{{ key + 1 + '.' }}</div>
            <textarea
              :id="`medical_diagnosis_${key}`"
              v-model="input.value"
              class="form-control"
              rows="1"
            />
          </div>
        </div>
      </div>

      <!-- additional section -->
      <div class="my-5">
        <h2 class="fs-3 text-dark d-inline">Additional</h2>
        <div>
          <textarea
            v-model="additionalInfo"
            class="form-control mt-2"
            rows="3"
          />
        </div>
      </div>

      <!-- other docs section -->
      <div>
        <h2 class="pt-2 mb-4 fs-3 text-dark">Other Documents</h2>

        <div>
          <!-- <button
            type="button"
            class="btn d-flex file-upload-btn"
            @click.prevent="upload.active = true"
          >
            <label
              for="more_docs"
              class="px-5 py-2 upload-btn border rounded-2"
            >
              <img src="@/assets/svg/folder.svg" class="me-2 icon-gray" />
              Upload additional documents
            </label> -->

          <!-- <input
              id="more_docs"
              type="file"
              multiple
              class="d-none"
              name="more_docs"
              accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              @change="FileUpload($event, 'additionalDocs')"
            /> -->
          <!-- </button> -->
          <div class="">
            <VueUploadComponent
              ref="uploadAdditionalDocs"
              v-model="additionalDocs.value"
              class="file-upload-btn border rounded-2 w-25 p-2"
              post-action="/upload/post"
              :multiple="true"
              :size="1024 * 1024 * 10"
            >
              <div class="d-flex justify-content-center align-item-center">
                <img src="@/assets/svg/folder.svg" class="me-2 icon-gray" />
                Select files
              </div>
            </VueUploadComponent>
          </div>
        </div>

        <div
          v-if="additionalDocs.value && additionalDocs.value.length > 0"
          class="mt-2"
        >
          <div
            v-for="(files, idx) in additionalDocs.value"
            :key="files.name ? files.name : files.file_name"
            style="font-size: 13px"
            class="d-flex align-items-center"
          >
            <button
              class="btn border-0 bg-transparent text-danger btn-sm"
              @click="
                storeDocToRemove({
                  category: 'additional documents',
                  file_index: idx,
                  ...files,
                })
              "
            >
              x
            </button>

            <div>
              <a
                v-if="files.file_name"
                :href="files.file"
                target="_blank"
                :download="files.file_name"
              >
                {{ files.file_name }}
              </a>
              <span v-else class="fw-light">{{ files.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-5">
        <button
          type="sumbit"
          class="btn btn-success rounded-3 text-white my-3 px-5"
          @click="submit"
        >
          SAVE INFORMATION
        </button>
      </div>
    </div>

    <div
      v-else
      class="min-vh-100 d-flex flex-column justify-content-center align-items-center"
    >
      <div class="text-primary p-3 mb-4">{{ loadingText }}</div>
      <div
        class="spinner-border text-primary"
        style="width: 12rem; height: 12rem"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    id="confirmationModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="confirmationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog text-center">
      <div class="modal-content">
        <div class="modal-body py-5">
          <span>Are you sure you want to delete</span>
          <br />
          <span class="fw-bold">
            "
            {{
              docToRemove.file ? docToRemove.file.name : docToRemove.file_name
            }}
            "
          </span>
          <span>from {{ docToRemove.category }} ?</span>
          <p class="mb-0 mt-1">You can't undo this action.</p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button
            type="button"
            class="btn bg-gray-300 me-4"
            data-bs-dismiss="modal"
            @click="docToRemove = null"
          >
            Cancel
          </button>
          <button
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-danger text-white"
            @click="delFile"
          >
            Delete file
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MedicalDocUploader from '@/components/TPD/Components/MedicalDocUploader.vue'
import { Modal } from 'bootstrap'
import VueUploadComponent from 'vue-upload-component'
import Editor from '@tinymce/tinymce-vue'
export default {
  name: 'ReferralInformationUpload',
  components: { MedicalDocUploader, Editor, VueUploadComponent },
  props: {
    assessmentId: { type: Number, default: null },
    readyNext: { type: Boolean },
    oldAssessmentData: {
      type: Object,
      default: null,
    },
  },

  emits: ['update:readyNext', 'update:toastText'],

  data() {
    return {
      pageReady: false,
      loadingText: 'loading data...',
      medicalDocs: [
        {
          title: 'GP Notes',
          id: 1,
          api_title: 'gp_notes',
          status: 'waiting',
          value: [],
        },
        {
          title: 'Specialist Documents',
          id: 7,
          api_title: 'specialist_docs',
          status: 'waiting',

          value: [],
        },
        {
          title: 'Surgical Note',
          id: 8,
          api_title: 'surgical_notes',
          status: 'waiting',

          value: [],
        },
        {
          title: 'Investigation reports',
          id: 3,
          api_title: 'investigation_reports',
          status: 'waiting',
          value: [],
        },
        {
          title: 'Hospital Record',
          id: 2,
          api_title: 'hospital_records',
          status: 'waiting',
          value: [],
        },
        {
          title: 'Medicolegal Documents',
          id: 5,
          api_title: 'medicolegal_docs',
          status: 'waiting',
          value: [],
        },
      ],
      legalDocs: {
        api_title: 'legal_letters',
        value: null,
      },
      medDocFormData: null,
      medicalDocLegends: [
        { title: 'Included', status: 'included' },
        { title: 'Waiting', status: 'waiting' },
        { title: 'Not Available', status: 'unavailable' },
      ],
      referralID: null,
      referral: null,
      //
      docToRemove: {
        file_name: '',
      },
      //
      medicalDiagnosis: [
        { id: 1, value: '' },
        { id: 1, value: '' },
        { id: 1, value: '' },
        { id: 1, value: '' },
        { id: 1, value: '' },
      ],
      //
      additionalInfo: '',
      additionalDocs: {
        api_title: 'other_docs',
        value: null,
      },
      //
      referralHistory: '',
      //
      briefingLetterStatus: 'waiting',
    }
  },

  computed: {},

  created() {
    this.referralID = this.$route.params.id
  },

  mounted() {
    this.displayExistingData(this.$store.state.TPDReferral)

    this.pageReady = true
  },

  methods: {
    onFileUpload(obj) {
      //vue doesnt allow v-model file type inputs

      this.medicalDocs[obj.index].value = obj.value
      this.medicalDocs[obj.index].status = 'included'
    },

    displayExistingData(obj) {
      //map or update referrals documents
      this.referral = obj

      if (this.referral) {
        //map medical docs
        for (const [key, value] of Object.entries(this.referral)) {
          let found = this.medicalDocs.find((x) => x.api_title === key)
          if (found) {
            found.value = value

            //set to included status when detect files
            value.length > 0 ? (found.status = 'included') : ''
          }
        }

        //map legal doc
        this.legalDocs.value =
          this.referral.legal_letters.length > 0
            ? this.referral.legal_letters
            : []

        //map other doc
        this.additionalDocs.value =
          this.referral.other_docs.length > 0 ? this.referral.other_docs : []

        //set unavailable status for medicial docs
        this.referral.unavailable_documents.forEach((id) => {
          let found = this.medicalDocs.find((doc) => doc.id === id)

          if (found) {
            return (found.status = 'unavailable')
          }
        })
      } else
        this.loadingText =
          'Missing data, please create the referral by starting from the first tab ( Tpd Referral )...'
    },

    async submit() {
      this.$store.commit('STORE_PATIENTDOCS', this.medicalDocs)

      await this.patchReferral()
    },

    storeFormData(val) {
      let name_array = []
      // Display the key/value pairs
      for (var pair of val.entries()) {
        if (pair[1].size === 0) {
          name_array.push(pair[0])
        }
      }

      name_array.forEach((key) => {
        val.delete(key)
      })

      let form_data = val

      this.medDocFormData = form_data
    },

    async patchReferral() {
      // append service_requirements as this is required when doing patch

      //set format
      this.medDocFormData = new FormData()

      this.medicalDocs.forEach((item) => {
        if (item.value.length > 0) {
          item.value.forEach((file) => {
            // this allows only local uploads to be appended into the form data for api calls
            return !Object.prototype.hasOwnProperty.call(file, 'uploaded_by')
              ? this.medDocFormData.append(`${item.api_title}`, file.file)
              : null
          })
        }
      })

      this.pageReady = false
      this.loadingText =
        'Please wait as we are currently checking and uploading files to server...'

      //convert legal doc to formData

      //due to file format from existing data from api vs uploaded file data from local is different, only append when file is uploaded from local
      if (
        this.legalDocs.value.length > 0 &&
        this.legalDocs.value !== this.referral.legal_letters
      ) {
        Array.from(this.legalDocs.value).forEach((file) => {
          this.medDocFormData.append(`${this.legalDocs.api_title}`, file.file)
        })
      }

      //do the same thing to additional doc like how u did for legal doc

      if (
        this.additionalDocs.value.length > 0 &&
        this.additionalDocs.value !== this.referral.other_docs
      ) {
        Array.from(this.additionalDocs.value).forEach((file) => {
          this.medDocFormData.append(
            `${this.additionalDocs.api_title}`,
            file.file
          )
        })
      }

      //compile unavailable document
      //unavailable_documents
      let unavailableAry = []
      this.medicalDocs.forEach((file) => {
        if (file.status === 'unavailable') {
          unavailableAry.push(file.id)
        } else false
      })

      unavailableAry.forEach((id) => {
        this.medDocFormData.append('unavailable_documents', id)
      })

      //ensure there is either unavailable markings on file category, or uploaded at least 1 file

      let readyForSubmit = this.medicalDocs.every(
        (category) =>
          category.status === 'unavailable' || category.value.length !== 0
      )

      if (this.referralID && readyForSubmit) {
        let value = {
          id: this.referralID,
          payload: this.medDocFormData,
        }

        await this.$store
          .dispatch('patchTPDReferral', value)
          .then((response) => {
            this.$store.commit('STORE_TPDREFERRAL', response.data)

            this.displayExistingData(response.data)

            this.pageReady = true

            // Trigger toast text
            this.$root.toasts.push({
              status: 'success',
              content:
                'Referral information and documents updated successfully!',
            })

            // Delay for the success toast
            setTimeout(
              () =>
                this.$router.push({
                  name: 'referral-created',
                  params: { id: this.referralID },
                }),
              1800
            )
          })

          .catch((error) => {
            this.pageReady = true
            if (error.response) {
              console.log(error.response)
            } else if (error.request) {
              console.log(error.request)
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message)
              // Trigger toast text
              this.$root.toasts.push({
                status: 'danger',
                content: `Error: ${error.message}`,
              })
            }
          })
      } else {
        alert(
          'Please ensure you marked all documents is either marked as unavailable or has a file uploaded, this is needed for referral creation.'
        )
        this.pageReady = true
      }
    },

    setUnavailable(idx) {
      this.medicalDocs[idx].status === 'unavailable'
        ? (this.medicalDocs[idx].status = 'waiting')
        : (this.medicalDocs[idx].status = 'unavailable')
    },

    //this updates the file to be removed
    storeDocToRemove(val) {
      //bootstrap scripts
      const myModal = new Modal('#confirmationModal')

      this.docToRemove = val

      return myModal.show()
    },

    //this triggers the api
    async delFile() {
      //if have id then remove with api call

      let value = {
        id: this.docToRemove.id,
      }

      //lets delete the file on frontend first

      if (this.docToRemove.category == 'legal documents') {
        this.legalDocs.value.splice(this.docToRemove.file_index, 1)
      } else if (this.docToRemove.category == 'additional documents') {
        this.additionalDocs.value.splice(this.docToRemove.file_index, 1)
      } else {
        let foundCategory = this.medicalDocs.findIndex(
          (cat) => cat.title === this.docToRemove.category
        )

        this.medicalDocs[foundCategory].value.splice(
          this.docToRemove.file_index,
          1
        )
        this.medicalDocs[foundCategory].value.length > 0
          ? (this.medicalDocs[foundCategory].status = 'included')
          : (this.medicalDocs[foundCategory].status = 'unavailable')
      }

      if (this.docToRemove.id) {
        await this.$store
          .dispatch('delTPDReferralDoc', value)
          .catch((error) => {
            console.log(error)
          })
      }
    },
  },
}
</script>

<style lang="scss">
.icon-gray {
  filter: brightness(0) saturate(100%) invert(99%) sepia(13%) saturate(0%)
    hue-rotate(142deg) brightness(82%) contrast(86%);
}

.modal.show {
  &::before {
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    height: 100vh;
    width: 100vw;
    background: black;
    opacity: 0.5;
  }
}
</style>
